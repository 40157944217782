exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-accessories-js": () => import("./../../../src/pages/accessories.js" /* webpackChunkName: "component---src-pages-accessories-js" */),
  "component---src-pages-blog-[id]-js": () => import("./../../../src/pages/blog/[id].js" /* webpackChunkName: "component---src-pages-blog-[id]-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-buyer-protection-js": () => import("./../../../src/pages/buyer-protection.js" /* webpackChunkName: "component---src-pages-buyer-protection-js" */),
  "component---src-pages-cleaning-js": () => import("./../../../src/pages/cleaning.js" /* webpackChunkName: "component---src-pages-cleaning-js" */),
  "component---src-pages-collections-js": () => import("./../../../src/pages/collections.js" /* webpackChunkName: "component---src-pages-collections-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-delivery-js": () => import("./../../../src/pages/delivery.js" /* webpackChunkName: "component---src-pages-delivery-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-how-to-sell-js": () => import("./../../../src/pages/how-to-sell.js" /* webpackChunkName: "component---src-pages-how-to-sell-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-merch-js": () => import("./../../../src/pages/merch.js" /* webpackChunkName: "component---src-pages-merch-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-sourcing-js": () => import("./../../../src/pages/sourcing.js" /* webpackChunkName: "component---src-pages-sourcing-js" */),
  "component---src-pages-style-js": () => import("./../../../src/pages/style.js" /* webpackChunkName: "component---src-pages-style-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-youtube-js": () => import("./../../../src/pages/youtube.js" /* webpackChunkName: "component---src-pages-youtube-js" */)
}

